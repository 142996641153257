import "./App.css";
import Header from "./Header.js";
import Searchbar from "./Searchbar.js";
import ContentCard from "./ContentCard.js";
import { useContext, useState } from "react";
import { Theme } from "./color.js";
import { ThemeContext } from "./context/ThemeContext";

function App() {
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { theme } = useContext(ThemeContext);
  const currentTheme = theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
  return (
    <div className={`app ${currentTheme}`}>
      <Header />
      <Searchbar setErrorMessage={setErrorMessage} setUserData={setUserData} />
      <ContentCard errorMessage={errorMessage} userData={userData} />
    </div>
  );
}
export default App;
