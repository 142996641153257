import React, { useState, useEffect } from 'react';
import { Card, CardMedia, Button, CardContent, Typography, List, ListItemButton, ListItemText, Box, Grid } from "@mui/material";
import { formatContactData, formatDate, getContactClasses } from "./hel.js";
import PlaceIcon from "@mui/icons-material/Place";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkIcon from "@mui/icons-material/Link";
import ApartmentIcon from "@mui/icons-material/Apartment";
import "./App.css";


const ContentCard = ({ errorMessage, userData }) => {
  const [showRepositories, setShowRepositories] = useState(false);
  const [repositories, setRepositories] = useState([]);
  const [selectedRepoIndex, setSelectedRepoIndex] = useState(null);
  const [repoContents, setRepoContents] = useState([]);
  const [fileContent, setFileContent] = useState(null);

  useEffect(() => {
    if (showRepositories && userData) {
      fetch(`https://api.github.com/users/${userData.login}/repos`)
        .then(response => response.json())
        .then(data => setRepositories(data))
        .catch(error => console.error('Error fetching repositories:', error));
    }
  }, [showRepositories, userData]);

  useEffect(() => {
    if (selectedRepoIndex !== null) {
      const selectedRepo = repositories[selectedRepoIndex].name;
      fetch(`https://api.github.com/repos/${userData.login}/${selectedRepo}/contents`)
        .then(response => response.json())
        .then(data => setRepoContents(data))
        .catch(error => console.error('Error fetching repository contents:', error));
    }
  }, [selectedRepoIndex, repositories, userData]);

  const handleButtonClick = () => {
    setShowRepositories(!showRepositories);
  };

  const handleRepoClick = (event, index) => {
    setSelectedRepoIndex(index);
    setFileContent(null); 
  };

  const handleFileClick = (filePath) => {
    const selectedRepo = repositories[selectedRepoIndex].name;
    fetch(`https://api.github.com/repos/${userData.login}/${selectedRepo}/contents/${filePath}`)
      .then(response => response.json())
      .then(data => {
        const decodedContent = atob(data.content);
        setFileContent(decodedContent);
      })
      .catch(error => console.error('Error fetching file content:', error));
  };

  if (errorMessage || !userData) {
    return (
      <div className="info-message">
        <h1 className="info-message__title">{errorMessage}</h1>
      </div>
    );
  }
  return (
    <>
      <Card
        className="card-container"
        sx={{
          color: "var(--theme-text-primary)",
          backgroundColor: "var(--theme-secondary-background-color)",
          borderRadius: "15px",
          width: "50%",
          textAlign: "center",
          padding: "0",
          gap: "16px",
        }}
      >
        <div className="card__image-container">
          <CardMedia
            component="img"
            sx={{
              width: "140px",
              height: "140px",
              borderRadius: "50%",
              margin: "auto",
            }}
            image={userData.avatar_url}
            alt={userData.name}
          />
        </div>
        <div className="card__content-container">
          <div className="card__content-header">
            <h1 className="card__content-name">{userData.name}</h1>
            <p className="card__content-date">{`Joined ${formatDate(
              userData.created_at
            )}`}</p>
            <p className="card__content-username">{`@${userData.login}`}</p>
            <p className="card__content-bio">
              {userData.bio ? userData.bio : "This profile has no bio."}
            </p>
          </div>
          <div className="card__content_stats">
            <div className="card__content-stats-container">
              <p className="card__content-stats-label">Repositories</p>
              <p className="card__content-stats-number">
                {userData.public_repos}
              </p>
            </div>
            <div className="card__content-stats-container">
              <p className="card__content-stats-label">Followers</p>
              <p className="card__content-stats-number">{userData.followers}</p>
            </div>
            <div className="card__content-stats-container">
              <p className="card__content-stats-label">Following</p>
              <p className="card__content-stats-number">{userData.following}</p>
            </div>
          </div>
          <div className="card__content-contact">
            <div className="card__content-contact-container" id="location">
              <PlaceIcon className="card__content-contact-icon" />
              <p className={getContactClasses(userData.location)}>
                {formatContactData(userData.location)}
              </p>
            </div>
            <div className="card__content-contact-container" id="twitter">
              <TwitterIcon className="card__content-contact-icon" />
              <p className={getContactClasses(userData.twitter_username)}>
                {formatContactData(userData.twitter_username)}
              </p>
            </div>
            <div className="card__content-contact-container" id="website">
              <LinkIcon className="card__content-contact-icon" />
              {!userData.blog ? (
                <p className={getContactClasses(userData.blog)}>
                  {formatContactData(userData.blog)}
                </p>
              ) : (
                <a
                  className="card__content-contact-info"
                  href={userData.blog}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`@${userData.login}`}
                </a>
              )}
            </div>
            <div className="card__content-contact-container" id="company">
              <ApartmentIcon className="card__content-contact-icon" />
              <p className={getContactClasses(userData.company)}>
                {formatContactData(userData.company)}
              </p>
            </div>
          </div>

          <Button variant="outlined" onClick={handleButtonClick}>
            {showRepositories ? "Hide Repositories" : "View Repositories"}
          </Button>
        </div>
      </Card>
      {showRepositories && (
        <Card
          className="card-container"
          sx={{
            color: "var(--theme-text-primary)",
            backgroundColor: "var(--theme-secondary-background-color)",
            borderRadius: "15px",
            width: "50%",
            textAlign: "center",
            padding: "0",
            gap: "16px",
            marginTop: "20px",
          }}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ width: '100%', bgcolor: '--theme-secondary-background-color' }}>
                  <List component="nav" aria-label="main mailbox folders">
                    {repositories.map((repo, index) => (
                      <ListItemButton
                        key={repo.id}
                        selected={selectedRepoIndex === index}
                        onClick={(event) => handleRepoClick(event, index)}
                      >
                        <ListItemText primary={repo.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </Grid>
              <Grid item xs={6}>
                {selectedRepoIndex !== null && (
                  <Box sx={{ width: '100%', bgcolor: '--theme-primary-background-color' }}>
                    <Typography color="#FF9999" variant="h6">{repositories[selectedRepoIndex].name} Contents:</Typography>
                    <List component="nav" aria-label="secondary mailbox folders">
                      {repoContents.map(content => (
                        <ListItemButton key={content.sha} onClick={() => handleFileClick(content.path)}>
                          <ListItemText primary={content.name} />
                        </ListItemButton>
                      ))}
                    </List>
                    {fileContent && (
                      <CardContent>
                        <Typography color='#4c6a9b' variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
                          {fileContent}
                        </Typography>
                      </CardContent>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};
export default ContentCard;