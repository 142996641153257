import { useState } from "react";
import { Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./App.css";

const Searchbar = ({ setErrorMessage, setUserData }) => {
  const [username, setUsername] = useState("");
  const handleOnSearch = () => {
    if (!username) {
      setUserData(null);
      setErrorMessage(null);
      return;
    }
    fetch(`https://api.github.com/users/${username}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          setErrorMessage("User not found, please try again.");
          setUserData(null);
          return;
        }
        setUserData(data);
        setErrorMessage(null);
      });
  };
  return (
    <TextField
      variant="outlined"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleOnSearch();
        }
      }}
      placeholder="Search Github username"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              sx={{
                color: "var(--theme-secondary-color)",
                width: "30px",
                height: "30px",
              }}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <Button
            variant="contained"
            onClick={handleOnSearch}
            size="small"
            sx={{
              marginRight: "0",
              borderRadius: "8px",
              height: "25px",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Search
          </Button>
        ),
      }}
      sx={{
        backgroundColor: "var(--theme-secondary-background-color)",
        borderRadius: "15px",
        width: "50%",
        ".MuiInputBase-input": {
          color: "var(--theme-text-primary)",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "none",
          },
          "&:hover fieldset": {
            borderColor: "var(--theme-secondary-color)",
            borderRadius: "15px",
          },
          "&.Mui-focused fieldset": {
            borderColor: "var(--theme-primary-color)",
            borderRadius: "15px",
          },
        },
      }}
    />
  );
};
export default Searchbar;
